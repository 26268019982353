// methods
import { addGrade, editGrade, deleteGrade, setGradeStatus, getSectionGradeList, getGradeIsUsed } from '@/api/basic';

// components
import { Button, Table, TableColumn, Form, FormItem, Input } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import ETable from '@components/table/index.vue';
import EButton from '@components/button/index.vue';
import OperationBtns from '@components/table/components/operationBtns.vue';
import Dialog from '@components/dialog/dialog.vue';
import NoData from '@components/noData/index.vue';

import cloneDeep from 'lodash.clonedeep';
import { checkPlusNumber } from '@/utils/validate';
import Vue from 'vue';

export default {
  name: 'grade',

  components: {
    NoData,
    EButton,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    basicHeader,
    qlPagination,
    ETable,
    Dialog,
  },

  data() {
    // 自定义手机验证
    const validatePlusNumber = (rule, value, callback) => {
      let msg = checkPlusNumber(value);
      if (msg !== true) {
        callback(new Error(msg));
        return;
      }
      callback();
    };

    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      visible: false,
      formData: {
        index: -1,
        gradeId: null,
        grade: '',
        period: '',
        lastPrefectId: null,
        prefectId: null,
        prefect: '',
      },
      prefectOptions: [],

      sectionList: [],

      gradeModal: {
        visible: false,
        title: '',
        rules: {
          periodName: [{ required: true }],
          gradeDefName: [{ required: true }],
          gradeName: [
            { required: true, message: '请输入年级别名', trigger: ['blur', 'change'] },
            { max: 20, message: '20字符，支持汉字/字母/数字/特殊字符', trigger: ['blur', 'change'] },
          ],
          seq: [
            { required: true, message: '请输入升学年序号', trigger: ['blur', 'change'] },
            { validator: validatePlusNumber, trigger: ['blur', 'change'] },
          ],
        },
        form: {},
      },
    };
  },

  methods: {
    clearValid() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    },

    async getTableData() {
      const res = await getSectionGradeList();

      if (res.status === 0) {
        this.sectionList = res.result;
      }
    },

    showGradeModal(row) {
      let title;
      if (row.gradeDefName) title = '编辑系统年级';
      else if (row.gradeId) title = '编辑自定义年级';
      else title = '新增自定义年级';

      this.gradeModal = {
        ...this.gradeModal,

        visible: true,
        title,
        form: cloneDeep(row),
      };

      this.clearValid();
    },

    async handleSave() {
      this.loading = true;

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { gradeName, seq, periodId, gradeId } = this.gradeModal.form;

          let res;

          if (gradeId) {
            res = await editGrade({
              gradeId,
              gradeName,
              seq,
            }).catch(() => (this.loading = false));
          } else {
            res = await addGrade({
              periodId,
              gradeName,
              seq,
            }).catch(() => (this.loading = false));
          }

          if (res.status === 0) {
            this.$message.success('操作成功');
            this.gradeModal.visible = false;
            this.getTableData();
          }
        } else {
          this.loading = false;
        }
      });
    },

    // 删除
    async handleDel(row) {
      const h = this.$createElement;

      // 判断是否被关联任教/录入班级
      const res = await getGradeIsUsed({
        gradeId: row.gradeId,
      });

      if (res.status === 0) {
        const { result } = res;

        if (result) {
          this.$msgbox({
            message: h('div', null, [
              h('h3', { class: 'T3B mg-b-12' }, `【${row.gradeName}】已被关联任教/录入班级，无法删除`),
              h('p', { class: 'T5-2' }, `请删除与该年级相关的任教/班级，再删除年级`),
            ]),
            type: 'warning',
            customClass: 'ql-message-box_notitle  ql-message-box_notitle-grade',
            showClose: false,
            showCancelButton: false,
          });

          return;
        }
      }

      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-12' }, `确定要删除【${row.gradeName}】吗？`),
          h('p', { class: 'T5-2' }, '删除后各业务模块的年级筛选项中不可见该年级，该年级不纳入任何统计'),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
        confirmButtonText: '删除',
        confirmButtonClass: 'btn-ghost-danger',
      })
        .then(() => {
          deleteGrade({ gradeId: row.gradeId }).then((res) => {
            if (res.status === 0) {
              this.$message.success('删除成功');
              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },

    // 禁用
    async handleBan(row) {
      // 直接开启
      if (row.status === 1) {
        setGradeStatus({ gradeId: row.gradeId, status: row.status === 1 ? 0 : 1 }).then((res) => {
          if (res.status === 0) {
            this.$message.success('启用成功');
            this.getTableData();
          }
        });

        return;
      }

      const h = this.$createElement;

      // 判断是否被关联任教/录入班级
      const res = await getGradeIsUsed({
        gradeId: row.gradeId,
      });

      if (res.status === 0) {
        const { result } = res;

        if (result) {
          this.$msgbox({
            message: h('div', null, [
              h('h3', { class: 'T3B mg-b-12' }, `【${row.gradeName}】已被关联任教/录入班级，无法禁用`),
              h('p', { class: 'T5-2' }, `请删除与该年级相关的任教/班级，再禁用年级`),
            ]),
            type: 'warning',
            customClass: 'ql-message-box_notitle ql-message-box_notitle-grade',
            showClose: false,
            showCancelButton: false,
          });

          return;
        }
      }

      this.$msgbox({
        message: h('div', null, [
          h('h3', { class: 'T3B mg-b-12' }, `确认要${row.status === 0 ? '禁用' : '启用'}【${row.gradeName}】吗？`),
          h('p', { class: 'T5-2' }, '禁用后各业务模块的年级筛选项中不可见该年级，该年级不纳入任何统计'),
        ]),
        type: 'warning',
        customClass: 'ql-message-box_notitle',
        showClose: false,
        showCancelButton: true,
        confirmButtonText: row.status === 0 ? '禁用' : '确定',
        confirmButtonClass: row.status === 0 ? 'btn-ghost-danger' : '',
      })
        .then(() => {
          setGradeStatus({ gradeId: row.gradeId, status: row.status === 1 ? 0 : 1 }).then((res) => {
            if (res.status === 0) {
              this.$message.success('操作成功');
              this.getTableData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
  },

  mounted() {
    this.getTableData();
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    notAllowOperate() {
      return false;
    },

    columns() {
      return (section) => {
        let col = [
          {
            label: '年级别名',
            prop: 'gradeName',
            component: Vue.extend({
              props: ['row', 'col'],
              render() {
                return <span class={this.row.status === 1 ? 'text-F3' : ''}>{this.row.gradeName || '-'}</span>;
              },
            }),
          },
          {
            label: '关联年级',
            prop: 'gradeDefName',
            component: Vue.extend({
              props: ['row', 'col'],
              render() {
                return <span class={this.row.status === 1 ? 'text-F3' : ''}>{this.row.gradeDefName || '-'}</span>;
              },
            }),
          },
          {
            label: '操作',
            props: () => ({
              btnList: [
                {
                  text: '编辑',
                  disabled: (row) => row.status === 1,
                  handle: this.showGradeModal,
                },
                {
                  // 系统年级可以禁用/启用
                  textHandle: (row) => ({ 0: '禁用', 1: '启用' }[row.status]),
                  hidden: (row) => !this.isSystem(row),
                  disabled: () => this.notAllowOperate,
                  handle: this.handleBan,
                },
                {
                  // 非系统年级可以删除
                  text: '删除',
                  hidden: (row) => this.isSystem(row),
                  disabled: () => this.notAllowOperate,
                  handle: this.handleDel,
                },
              ],
            }),
            component: OperationBtns,
          },
        ];

        if (section.periodName === '其他') {
          col[1] = {
            label: '升年级序号',
            prop: 'seq',
          };
        }

        return col;
      };
    },

    isSystem() {
      return (row) => row.gradeDefName;
    },

    isEdit() {
      return (row) => row.gradeId;
    },
  },

  watch: {
    'gradeModal.visible'(visible) {
      if (visible) {
        this.loading = false;
      }
    },
  },
};
